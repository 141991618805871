import * as React from 'react'
import '../../styles/global.css'
import { Link } from 'gatsby'
import {Helmet} from "react-helmet";
import Particles from "react-tsparticles";
import Zoom from 'react-reveal/Slide' // Importing Zoom effect

export default function ServiceFinder() {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
    return (
    <div pageTitle="Text Adventure">
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Service Finder - USI</title>
        <meta name="description" content="A full list of services we provide."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>
  
      <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#192958",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 20,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 100,
            enable: true,
            opacity: 0.2,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.3,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 15,
          },
        },
        detectRetina: true,
      }}
    />

    <Zoom bottom>
      <div className="">
      <Link to="../branding" className='h-12 w-12 m-6 float-right'>
        <img src="/img/icons/question-mark.png"></img>
      </Link>
      <Link to="../start" className='h-12 w-12 m-6 float-left'>
        <p className='text-white font-semibold font-heading text-lg'>START</p>
      </Link>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-28 gap-y-4 md:w-4/6 lg:w-3/6 xl:w-2/6 mx-auto py-36">
          <Link to="../branding" className="adventure-btn rounded-full text-center h-48 w-48 mx-auto ring-2 ring-white ring-offset-white"
          >
            <p className='py-14 text-xl px-6'>BRANDING<br/>AND<br/>STRATEGY</p>
          </Link>
          <Link to="../start" className="adventure-btn rounded-full text-center h-48 w-48  mx-auto ring-2 ring-white	ring-offset-white	"
          >
            <p className='py-14 text-xl px-4'>ARCHITECTURE AND<br/>PUBLIC ART</p>
          </Link>
          <Link to="../start" className="adventure-btn rounded-full text-center h-48 w-48  mx-auto ring-2 ring-white	ring-offset-white	"
          >
            <p className='py-14 text-xl px-4'>WELLBEING AND PLACEMAKING</p>
          </Link>
          <Link to="../start" className="adventure-btn rounded-full text-center h-48 w-48  mx-auto ring-2 ring-white	ring-offset-white	"
          >
            <p className='py-14 text-xl px-4'>CULTURAL TOURISM<br/>AND EVENTS</p>
          </Link>
          <Link to="../start" className="adventure-btn rounded-full text-center h-48 w-48  mx-auto ring-2 ring-white	ring-offset-white	"
          >
            <p className='py-14 text-xl px-4'>MANAGEMENT AND ENGAGEMENT</p>
          </Link>
          <Link to="../start" className="adventure-btn rounded-full text-center h-48 w-48  mx-auto ring-2 ring-white	ring-offset-white	"
          >
            <p className='py-14 text-xl px-4'>POLICY<br/>AND<br/>INSIGHTS</p>
          </Link>
        </div> 

        <Link to="https://urbanscaleinterventions.com/" className='h-12 w-16 mr-6 float-right'>
          <img src="https://ik.imagekit.io/5y988hvt8sd/USI/usi_jPyuDpBGj.png?updatedAt=1638527502632"></img>
        </Link>

      </div>
      </Zoom>
      </div>
    )
  }
  